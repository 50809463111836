import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import Slideshow from "../components/ProjectPage/Slideshow"
import {
  Container,
  Box,
  Heading,
  Flex
} from '@chakra-ui/react'
import styled from '@emotion/styled';

const ProjectHeading = styled.p`
  font-weight: 700;
  font-size: 1rem;
`;


const ProjectPageTemplate = ({ data, location }) => {
  
  const document = data.prismicProject.data;
  
  const mobileProjectImages = document.mobileSlides;
  

  return (
    <Layout
      location={location}
    >
      <Heading as="h2" textAlign="center" fontWeight="900" my="24">
        {document.project_title}
      </Heading>
        
        <Slideshow 
          projectImages={document.project_images}
          mobileImages={mobileProjectImages}
        />

        <Container maxW="container.md">
          <Flex w="100%" my="24" justifyContent="space-between" flexWrap="wrap">

              {document.project_description.text && (
                <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                  <ProjectHeading>Description</ProjectHeading>
                </Box>
                <Box w={{base: '100%', md: '80%'}}>
                  <p>{document.project_description.text}</p>
                </Box>
              </Flex>
              )}

              {document.location.text && (
                <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                  <ProjectHeading>Location</ProjectHeading>
                </Box>
                <Box w={{base: '100%', md: '80%'}}>
                  <p>{document.location.text}</p>
                </Box>
              </Flex>
              )}
              
              {document.completion.text && (
                <Flex w="100%" flexWrap="wrap">
                  <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                    <ProjectHeading>Completion</ProjectHeading>
                  </Box>
                  <Box w={{base: '100%', md: '80%'}}>
                    <p>{document.completion.text}</p>
                  </Box>
                </Flex>
              )}


            {document.architect.text && (
              <Flex w="100%" flexWrap="wrap">
              <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                <ProjectHeading>Architect</ProjectHeading>
              </Box>
              <Box w={{base: '100%', md: '80%'}}>
                <p>{document.architect.text}</p>
              </Box>
           </Flex>
            )}

            {document.contractor.text && (
               <Flex w="100%" flexWrap="wrap">
               <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                 <ProjectHeading>Contractor</ProjectHeading>
               </Box>
               <Box w={{base: '100%', md: '80%'}}>
                 <p>{document.contractor.text}</p>
               </Box>
            </Flex>
            )}

            {document.details.text && (
              <Flex w="100%" flexWrap="wrap">
              <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                <ProjectHeading>Details</ProjectHeading>
              </Box>
              <Box w={{base: '100%', md: '80%'}}>
                <p>{document.details.text}</p>
              </Box>
           </Flex>
            )}
             
            {document.sustainability.text && (
              <Flex w="100%" flexWrap="wrap">
              <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                <ProjectHeading>Sustainability</ProjectHeading>
              </Box>
              <Box w={{base: '100%', md: '80%'}}>
                <p>{document.sustainability.text}</p>
              </Box>
           </Flex>
            )}
             
            {document.photographer.text && (
              <Flex w="100%" flexWrap="wrap">
              <Box w={{base: '100%', md: '20%'}} h={{base: 'auto', md: '100%'}}>
                <ProjectHeading>Photographer</ProjectHeading>
              </Box>
              <Box w={{base: '100%', md: '80%'}}>
                <p>{document.photographer.text}</p>
              </Box>
           </Flex>
            )}
            
          </Flex>
        </Container>

    </Layout>
  )
}

export const query = graphql`
query ProjectPageQuery($uid: String!)
{
    prismicProject(uid: {eq: $uid}) {
      url
      uid
      data {
        project_title
        sustainability {
          text
        }
        project_images {
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED, height: 400)
              }
            }
          }
        }
        mobileSlides: project_images {
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED, height: 250)
              }
            }
          }
        }
        architect {
          text
        }
        completion {
          text
        }
        contractor {
          text
        }
        details {
          text
        }
        location {
          text
        }
        photographer {
          text
        }
        project_description {
          text
        }
      }
    }
  }
`

export default ProjectPageTemplate

export const Head = ({ data }) => {
  const pageTitle = data.prismicProject.data.project_title
  return (
    <title>{`Cloud 9 Builders | ${pageTitle}`}</title>
  )
}