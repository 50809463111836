import React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

export default function SlideShow(props) {
  return (
    <>
      <Box display={{base: 'none', lg: 'block'}}>
        <Splide
          options={ {
            rewind: true,
            gap: '1rem',
            autoWidth: true,
          }}
          aria-label={props.projectTitle}
        > 
        {props.projectImages.map((projectImage, index) => {
          return (
            <SplideSlide key={index}>
              <Box w="100%" h="100%">
                <GatsbyImage image={getImage(projectImage.image.localFile)} alt={projectImage.image.alt} imgStyle={{width: '100%', height: 'auto'}} />
              </Box>
            </SplideSlide>
          )
        })}
        </Splide>
      </Box>
      <Box display={{base: 'block', lg: 'none'}}>
      <Splide
        options={ {
          rewind: true,
          gap: '1rem',
          autoWidth: true,
          focus: 'center'
        }}
        aria-label={props.projectTitle}
      > 
      {props.mobileImages.map((projectImage, index) => {
        return (
          <SplideSlide key={index}>
            <Box>
              <GatsbyImage image={getImage(projectImage.image.localFile)} alt={projectImage.image.alt} height="400px" />
            </Box>
          </SplideSlide>
        )
      })}
      </Splide>
    </Box>
    </>
  )
}